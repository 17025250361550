(function () {
  'use strict';

  angular
  .module('neo.home.refereeNewInvoice.facturar')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.facturar', {
      url: '/facturar/{fechaini}/{fechafin}/{idAjuntament}/{ids}',
     
          templateUrl: 'home/referee-new-invoice/facturar/facturar.tpl.html',
          controller: 'facturar',
          controllerAs: 'vm',
        resolve:{
          townHalls: function (Ajuntaments) {
            return Ajuntaments.query().$promise;
          },
        fechaini:function ($stateParams){
             return $stateParams.fechaini;
           },
        fechafin:function ($stateParams){
             return $stateParams.fechafin;
           },
           
        ajuntament:function ($stateParams){
             return $stateParams.idAjuntament;
           },
            ids:function ($stateParams){
             return $stateParams.ids;
           }
          
                  
        }
       
    });
  }
}());

